
import { defineComponent, onMounted, reactive } from "vue";
import mAxiosApi from "@/api";

import Cisco from "@/views/still/contrats/supports/CISCO.vue";
import Juniper from "@/views/still/contrats/supports/Juniper.vue";
import Fortinet from "@/views/still/contrats/supports/FORTINET.vue";
import ExtremeNetworks from "@/views/still/contrats/supports/ExtremeNetworks.vue";
import Polycom from "@/views/still/contrats/supports/Polycom.vue";
import Synology from "@/views/still/contrats/supports/Synology.vue";

export default defineComponent({
  name: "deploy-overview",
  components: {
    Cisco,Fortinet,
    ExtremeNetworks,Polycom,Synology, Juniper
  },
  setup() {
    const state = reactive({
      constructeur: "",
      myList: [] as any,
    });

    const socDispo = {'CISCO': 1, 'JUNIPER': 1, 'FORTINET': 1, 'Extreme Networks': 1, 'Polycom': 1, 'SYNOLOGY':1 }

    onMounted(async () => {
      // ;
      let myList = await mAxiosApi.prototype.getAxios("/getConstructeur");
      state.myList = myList.records;  
      if (state.myList.length > 0) state.constructeur = state.myList[0].soc_nom_societe.toUpperCase();

    });

    const selectFAQ = (con) => {
      state.constructeur = con.soc_nom_societe.toUpperCase();
    }

    return {
      selectFAQ,
      state,
      socDispo,
    };
  },
});
