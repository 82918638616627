<template>
        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Polycom_logo_2012.svg/1280px-Polycom_logo_2012.svg.png" class="mb-10" style="float:right; max-width: 200px; margin-right: 10px;  margin-left: 40px;"/>
        <h1>{{$t("Polycom")}}</h1>
        <div style="word-break: break-word;" class="fs-1 mt-20">{{$t("Via un appel téléphonique")}}</div><br>
        <div style="word-break: break-word;" class="fs-5">
            {{$t("Vous pouvez contacter l’assistante par téléphone :")}} <br><br>
                <b>{{$t("Pour le support contractuel")}}</b><br>
                  {{$t("Assistance en anglais disponible 24h / 24 et 7j / 7")}}         <br><br>       
                 <b>{{$t("Pour le support standard")}}</b><br>
                  {{$t("Du lundi au vendredi - de 9h00  à 17h30")}}<br>
                  {{$t("Depuis la france :")}} <span class="text-primary">&nbsp;&nbsp;0800 945 770 </span> <br><br>
                  {{$t("Pour contacter le support partout dans le monde, vous pouvez utiliser les numéros que vous trouverez dans la liste via le lien :")}} <a target="_blank" href="https://www.poly.com/fr/fr/support/contact/support-phone-numbers">https://www.poly.com/fr/fr/support/contact/support-phone-numbers</a>

        </div>  

        <div style="word-break: break-word;" class="fs-1 mt-15">{{$t("Via un formulaire")}}</div><br>
        <div style="word-break: break-word;" class="fs-5 mb-5">
          {{$t("Vous devez compléter le formulaire d’assistante sur la page suivante :")}} <a target="_blank" href="https://www.poly.com/fr/fr/support/contact/email-support# ">https://www.poly.com/fr/fr/support/contact/email-support#</a>
        </div>



</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";

export default defineComponent({
  name: "deploy-overview",
  components: {},
    props: {
    display: String,
  },
  setup(props) {

    onMounted(async () => {
      // 
    });
    const mProps = props;
    return { 
        mProps,
    };
  },
});
</script>
