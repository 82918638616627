<template>
        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/Synology_Logo.svg/1200px-Synology_Logo.svg.png" class="mb-10" style="float:right; max-width: 200px; margin-right: 10px;  margin-left: 40px;"/>
        <h1>{{$t("SYNOLOGY")}}</h1>
        <div style="word-break: break-word;" class="fs-1 mt-20">{{$t("Via l’interface Synology")}}</div><br>
        <div style="word-break: break-word;" class="fs-5">
            {{$t("Pour soumettre un ticket d’assistance ou suivre son statut, connectez-vous à votre compte Synology en cliquant sur le lien suivant :")}} <a target="_blank" href="https://account.synology.com/fr-fr/oauth2/login/support?login_challenge=9ad7000ceb96416f9e968ca1d6fac689">https://account.synology.com/fr-fr/oauth2/login/support?login_challenge=9ad7000ceb96416f9e968ca1d6fac689</a><br><i>{{$t("(renseigner son e-mail et son mot de passe)")}}</i></div>
        <div style="word-break: break-word;" class="fs-1 mt-15">{{$t("Via un appel téléphonique")}}</div><br>
        <div style="word-break: break-word;" class="fs-5">
            {{$t("Le service client Synology de France est joignable du lundi au vendredi de 9h à 17h :")}} <span class="text-primary">&nbsp;&nbsp;01 47 17 62 88</span>.
        </div>  



</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";

export default defineComponent({
  name: "deploy-overview",
  components: {},
    props: {
    display: String,
  },
  setup(props) {

    onMounted(async () => {
      // 
    });
    const mProps = props;
    return { 
        mProps,
    };
  },
});
</script>
